import Vue from 'vue'
import axios from '@/plugins/axios'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import vuelidate from '@/plugins/vuelidate'
import VueMeta from 'vue-meta'
import { translate } from '@/messages'
import './plugins/vuetifyMask.js'
import './plugins/leaflet'
import '../public/registerServiceWorker'
import './plugins/vcdonut'
import './plugins/wcharts'
import uploader from '@/plugins/uploader'
import PortalVue from 'portal-vue'
import lodash from 'lodash'
import * as turf from '@turf/turf'


Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(PortalVue)

Vue.prototype.$t = translate
Vue.prototype.$can = store.getters['auth/can']
Vue.prototype['_.'] = lodash
Vue.prototype.$turf = turf

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
// это подключает наш собственный файл service-worker, без этого подключения он создается автоматически.
navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`)
}

new Vue({
  router,
  store,
  vuetify,
  axios,
  vuelidate,
  uploader,
  render: h => h(App)
}).$mount('#app')
